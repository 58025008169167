.headwise-expense-table {
    /*background-color: white;
    background: rgba(25, 31, 38, 0.75); */
    
    width: 100%;
    margin-bottom: 5px;
    min-height: 100%;
}

.headwise-expense-table > thead {
   /* background-color: lightblue;
    color: midnightblue;*/
}

.headwise-expense-table td {
    /*background-color: white;*/
}

.headwise-expense-table .legends {
    text-align: center;
}

span.expense-square {
    margin-right: 10px;
}

span.expense-square::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #f27256;
    display: inline-block;
    margin-right: 4px;
}

span.budget-square::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #85a63d;
    display: inline-block;
    margin-right: 4px;
}

.headwise-expense-table .label {
    text-align: end;
    padding-right: 3px;
    color: white;
}

.headwise-expense-table .bars {
    width: 80%;
    padding: 10px 20px;
    border-top: 2px solid darkslategray;
    border-bottom: 2px solid darkslategray;
    border-left: 1px solid darkslategray;
}

div.expense-bar, div.budget-bar {
    height: 30px;
    display: inline-block;
    text-align: center;
    color: white;
}

div.expense-bar {
    background-color: #f27256;
}

div.budget-bar {
    background-color: #85a63d;
}

.headwise-expense-table tfoot {
    text-align: center;
}
.flags-table {
    width: 100%;
    margin-bottom: 10px;
}

.flags-table td {
    /*background-color: white;*/
    text-align: justify;
}

.flags-table td.bars {
    padding: 10px 0px;
}

.flags-table thead {
    /*
    background-color: lightblue;
    color: midnightblue;
    */
}

/* .flags-table tbody tr td:nth-child(2), .flags-table tbody tr td:nth-child(3) {
    color: red;
} */

/* .flags-table tbody tr td:nth-child(3) {
    text-align: left;
} */

.flags-table tbody tr td:first-child {
    padding-left: 7%;
    /* max-width: 80px; */
}

.flags-header {
    font-weight: bold;
    text-decoration: underline;
    padding: 15px 0px;
}

.flags-table .budget-exceeded .bars .bar {
    background-color: #f6652f;
}

.flags-table .near-budget .bars .bar {
    background-color: olivedrab;
}

.flags-table .under-utilized .bars .bar {
    background-color: goldenrod;
}
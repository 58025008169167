.ytd-new-legend {
    position: absolute;
    right: 0;
    margin-right: 15px;
    margin-top: -20px;
    font-size: small;
}

.ytd-legend-square::before {
    content: ' ';
    margin-right: 5px;
    margin-left: 10px;
    background-color: rgb(166, 207, 75);
    width: 10px;
    height: 10px;
    display: inline-block;
}

.ytd-new-legend > .ytd-legend-square:first-of-type::before {
    background-color: rgb(228, 240, 210);
}
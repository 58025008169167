.total-budget-container .total-budget-textbox, .total-budget-container .budget-search-box {
    border: 1px solid rgb(111, 111, 111);
    background: rgba(78, 80, 84, 0.467);
    display: inline-block;
}

.total-budget-container .budget-search-box {
    margin-left: 10px;
}

.total-budget-container {
    display: flex;
}

.budget-tables-container table {
    width: 100%;
}

.budget-screen .expenses-table-container {
    padding-top: 10px;
}

.budget-screen .table-3 tr:last-child td {
    padding: 5px 0px;
}

.budget-screen .table-3-container {
    padding: 3px;
}

.budget-screen .table-2-container {
    padding: 10px 3px;
}

.budget-screen .table-1-container {
    padding: 3px;
}
.budget-screen .table-1-container th {
    background: rgba(78, 80, 84, 0.466);
    //text-align: center;
}

.budget-tables-container > table > tbody > tr > td {
    background: transparent;
}

.budget-top-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-budget-label, .total-budget-textbox {
    display: inline-block;
}

.total-budget-label {
    white-space: nowrap;
    margin-top: auto;
    margin-bottom: auto;
}

.budget-type-chooser {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 220px;
}

@media screen and (max-width: 974px) {
    .budget-top-panel {
        flex-direction: column;
        align-items: flex-start;
    }

    .budget-type-chooser {
        width: 100%;
    }

    .total-budget-container {
        margin-top: 5px;
    }

    .total-budget-container {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .total-budget-textbox {
        width: 100%;
    }
}
.yoy-table {
    width: 100%;
    margin-bottom: 5px;
}

.yoy-table thead {
    /*
    background-color: lightblue;
    color: midnightblue;
    */
}

.yoy-table td {
   /* background-color: white;*/
}

.yoy-table tfoot {
    text-align: center;
}

.yoy-table span.series1::before, .yoy-table span.series2::before {
    content: "";
    width: 25px;
    height: 5px;
    display: inline-block;
    margin-right: 3px;
}

.yoy-table span.series1::before {
    background-color: #a6cf4b;
}

.yoy-table span.series2::before {
    background-color:#b294c2;
    margin-left: 10px;
}

.yoy-expense-line {
    stroke: #a6cf4b;
    stroke-width: 3px;
    fill: none;
}

.yoy-prev-expense-line {
    stroke: #b294c2;
    stroke-width: 3px;
    fill: none;
}
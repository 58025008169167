.privilege-screen-container {
    border: 1px solid white;
}

.privilege-table {
    width: 100%;
    border: 1px solid white;
    border-spacing: 10px;
    border-collapse: inherit;
    margin-bottom: 10px;
}

.privilege-table td {
    padding: 40px;
    border: 1px solid white !important;
    width: 50%;
}
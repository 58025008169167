.goal-input-container > .row {
    margin-bottom: 10px;
}

.goal-settings-container > .row {
    margin-bottom: 10px;
}

.goal-search-button > .btn-wrap {
    text-align: left;
    display: inline-block;
    margin-right: 10px;
}
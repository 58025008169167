.family-code-editor {
    border-left: 1px solid white;
}

.prod-family-management-container .family-mapper-search {
    text-align: center;
}

.prod-family-management-container > div {
    padding: 10px;
}

.prod-family-management-container .row {
    margin-bottom: 10px;
}
.mfg-lookup-table-container {
    margin-top: 10px;
}

.mfg-lookup-search-button-container > * {
    display: inline-block;
    margin-left: 10px;
    margin-right: 0px;
}

.mfg-lookup-search-button-container > .zip-update-button-container > .btn-wrap {
    margin-left: 0px;
}
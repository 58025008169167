.user-privileges-container h6 {
    margin-bottom: 10px;
}

.user-privileges-container > .search-container {
    margin-bottom: 10px;
}

div.user-privileges-container {
    padding-right: 10px;
    border-right: 1px solid white;
}

div.role-privileges-container {
    border-right: none;
    padding-right: 5px;
    padding-left: 0px;
}
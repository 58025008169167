.first-dashboard-row {
    height: 65%;
   /* border-bottom: 1px solid black;*/
    overflow:auto;
}

.second-dashboard-row {
    height: 35%;
    overflow:auto;
}

.nav-row {
    padding: 0;
}

.dashboard-container {
    position: relative;
    display: inline-block;
    padding: 15px;
    padding-top: 3px;
}

.dashboard-container h2 {
    color: white;
    position: absolute;
    top: 0;
    left: 20px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    text-decoration: dashed;
}

.dashboard-container h5 {
    color: black;
    text-align: center;
}

.dashboard-cell-1, .dashboard-cell-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgba(25, 31, 38, 0.75); 
}

.dashboard-cell-1 {
    /*border-right: 1px solid black;*/
    padding-top: 15px;
    margin-bottom: 10px;
    position: relative; 
    
}

.dashboard-cell-2 {
    margin-left: 10px;
    margin-bottom: 10px;
    flex: 1 0 3%;
    padding-top: 15px;
}

.dashboard-cell-3 {
    flex: 1 0 66%;
}

.dashboard-cell-4 {
    flex: 1 0 34%;
    padding-top: 30px;
}

.dashboard-cell-5 {

    padding-bottom: 0px;
}

.dashboard-cell-3, .dashboard-cell-4 {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(25, 31, 38, 0.75); 
}

.dashboard-cell-3 td {
    white-space: nowrap;
}

.dashboard-cell-3 > div {
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 767px) {
    .dashboard-cell-2 {
        margin-left: 0px;
    }
}
.dashboard-dropdowns-container select {
    margin-top: 5px;
    color: white;
    height: 32px;
    padding: 0px;
}

.dashboard-dropdowns-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1px;
    padding-left: 10px;
}

.dashboard-dropdown {
    min-width: 150px;
    margin-right: 10px;
}

.dashboard-search-button {
    margin-right: 5px;
}

.dashboard-search-button button {
    margin-top: 5px;
    height: 32px;
}

.dashboard-search-button button img {
    height: 20px;
    width: 20px;
    position: relative;
    top: -2px;
}

.first-dashboard-row div.icons {
    position: absolute;
    top: 0;
    right: 0;
}

.first-dashboard-row .icons img {
    margin-right: 5px;
    cursor: pointer;
}

.first-dashboard-row .icons span {
    margin-right: 5px;
    color: black;
}

img.rotate180 {
    transform: rotate3d(1,900,0,180deg);
}

img.disabled {
    filter: grayscale(100%);
    pointer-events: none;
}

.kpiTable table{
/*background-color: aquamarine;
border: 1px solid black;*/
opacity: 0;

}

.sub_div { 
    position: absolute; 
    bottom: 5px;
    right: 10px; 
}

.third-dashboard-row {
    background: rgba(25, 31, 38, 0.75);
    padding-bottom: 10px;
}

@media screen and (max-width: 810px) {
    .dashboard-dropdowns-container {
        flex-direction: column;
    }

    .dashboard-dropdowns-container > div {
        width: 100%;
    }

    .dashboard-search-button {
        margin-right: 10px;
    }
}

@media (max-width: 795px) {
    .dashboard-cell-3 {
        flex-wrap: wrap;
        flex: 1 0 100%;
    }
    
    .dashboard-cell-3 > table {
        flex: 1 0 100%;
        margin-top: 40px;
    }

    .dashboard-cell-3 > div {
        flex: 1 0 100%;
        margin-top: 40px;
    }
}

.dashboard-container .graph-container {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 5px;
}

.dashboard-container .graph-container h2 {
    position: relative;
    margin-bottom: 15px;
    margin-top: 0px;
    left: 0;
  }

.dashboard-container .graph-container svg g.tick text {
    font-size: 1em!important;
    font-family: 'Montserrat';
}
.dashboard-container .graph-container svg g:not(.tick) text {
    font-size: 0.7em;
    font-family: 'Montserrat';
}
.dashboard-container .graph-container svg g[text-anchor=middle] g.tick text {
    font-size: 0.93em!important;
}
.dashboard-container .graph-container svg g[text-anchor=end] g.tick text {
    font-size: 0.9em!important;
}
.dashboard-container .graph-container.type-sales svg g.tick text {
    font-size: 1.15em!important;
}
.dashboard-container .graph-container.type-sales svg g:not(.tick) text {
    font-size: 0.7em;
}
.dashboard-container .graph-container.type-sales svg g[text-anchor=middle] g.tick text {
    font-size: 1.12em!important;
}
.dashboard-container .graph-container.type-sales svg g[text-anchor=end] g.tick text {
    font-size: 1.1em!important;
}
.dashboard-container .graph-container.type-ytd svg g.tick text {
    font-size: 0.72em!important;
}
.dashboard-container .graph-container.type-ytd svg g:not(.tick) text {
    font-size: 0.55em;
}
.dashboard-container .graph-container.type-ytd svg g[text-anchor=middle] g.tick text {
    font-size: 0.72em!important;
}
.dashboard-container .graph-container.type-ytd svg g[text-anchor=end] g.tick text {
    font-size: 0.8em!important;
}
@media (max-width: 767px) {
    .dashboard-container {
        padding: 15px 5px;
    }
    .dashboard-container .graph-container.type-sales svg g[text-anchor=middle] g.tick text {
        font-size: 1.12em!important;
    }
    .dashboard-container .graph-container.type-sales svg g[text-anchor=end] g.tick text {
        font-size: 1.2em!important;
    }
}

optgroup {
    color: black;
}

.flags-bar-table {
    width: 100%;
}

.flags-bar-table .bars {
    width: 70%;
}

.flags-bar-table .bars .bar {
    background-color: steelblue;
}
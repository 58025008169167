.full-width {
    width: 100%;
}

.leaderboard-table tr td:first-of-type {
    width: 1%;
}

.leaderboard-table tr td:nth-of-type(2) {
    padding-left: 7px;
}

.rounded-corners {
    border-radius: 25px;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	color: white;
	background: #364654;
    /*background-color: #c1ae38;
	border: 1px solid #c1ae38;*/
	border: #6c8399 solid 1px;
	padding: 8px 20px;
	font-size: small;
	font-weight: 500;
    display: inline-block;
    cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
	background-color: transparent;
	/*color: #c1ae38;*/
	
    color: #0291f6;
    border: #0291f6 solid 1px;
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

label.uploadlabeldisabled, label.uploadlabeldisabled:hover {
	background: #afafaf;
    border: none;
	cursor: not-allowed;
	color: white;
}
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    color: black;
}

.popup-container > div {
    width: 30vw;
    background-color: darkgray;
    top: 50%;
    left: 50%;
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 5px;
    text-align: center;
}

.popup-header {
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.popup-container button {
    margin-left: 10px;
    margin-bottom: 10px;
}

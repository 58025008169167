.search-box-container {
    /* border: 1px solid white; */
    padding: 0;
    margin-bottom: 10px;
}

.zip-search-box .search-button-container {
    text-align: center;
}

.search-button-container > button {
    width: 100%;
}

.zip-search-box input {
    margin-bottom: 5px;
}

.search-box-container > fieldset {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 0.35em;
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border: 2px groove !important;
    border-color: white;
}

.search-box-container > fieldset > legend {
    width: initial;
}

.zip-update-button-container {
    justify-content: flex-end;
    margin-right: auto;
}

.zip-update-button-container > * {
    margin-left: 10px;
}
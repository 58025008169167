.leadership-line-graph-table {
    width: 100%;
    height: 50%;
}

.ytd-current-line, .ytd-previous-line, .ytd-prevToPrev-line {
    fill: none;
    stroke-width: 2px;
}

.ytd-current-line {
    stroke: orange;
}

.ytd-previous-line {
    stroke: yellowgreen;
}

.ytd-prevToPrev-line {
    stroke: darkred;
}

.current-ytd-line-legend {
    justify-content: center;
    width: 50%;
}

.current-ytd-line-legend {
    text-align: right;
}

.previous-ytd-line-legend {
    padding-left: 15px;
    width: 8%;
}

.prevToPrev-ytd-line-legend {
    padding-left: 15px;
}

.current-ytd-line-legend div {
    background-color: orange;
}

.previous-ytd-line-legend div {
    background-color: yellowgreen;
}

.prevToPrev-ytd-line-legend div {
    background-color: darkred;
}
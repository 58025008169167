.filter-dropdown-wrapper {
    position: relative;
}

.filter-dropdown-arrow {
    position: absolute;
    top: 10px;
    right: 5px;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px !important;
    color: #fff;
    font-family: Montserrat;
}
.MuiAutocomplete-input {
    height: 20px !important;
}


.expense-container {
  /*  background-color: white;
    color: black;*/
}

.expense-dropdowns {
    border-bottom: 1px solid black;
    padding-bottom: 5px;
    justify-content: flex-end;
    display: flex;
}

.expense-dropdowns select {
    margin-top: 5px;
    /*color: black;
    background: white;*/
    height: 21px;
    padding: 0px;
}

.expense-dropdowns .filter-button-container {
    display: flex;
    /* align-items: center;
    justify-content: space-around; */
    justify-content: flex-end;
    align-items: center;
}

.expense-upload-div{
    border: 5px;
    border-color:red;
}

/*
.expense-dropdowns .filter-button-container button {
    height: 23px;
    padding: 0px 10px;
    margin: 0px;
    font-size: smaller;
    background: olivedrab;
    color: white;
    border: none;
}

.expense-dropdowns .filter-button-container button:hover {
    background: #f27256;
}
*/

.expense-container .top-left {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}

.expense-container .top-right {
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}

.budget-details-container {
    padding-top: 5px;
}

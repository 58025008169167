.box {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    border: 1px solid;
    justify-content: center;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__button,
.box__error {
  display: none;
}

.box__file {
    visibility: hidden;
    position: absolute;
}

.box__icon {
    font-size: 70px;
}

.box__input > label {
    display: block;
    font-size: 20px;
}

.box__input > label > strong:hover {
    color: wheat;
    cursor: pointer;
}

.box__icon:hover {
    color: wheat;
    cursor: pointer;
}

.box.has-advanced-upload {
    background: rgba(78, 80, 84, 0.466);
    outline: 2px dashed white;
    outline-offset: -10px;
    width: 100%;
    min-height: 30vh;
}

.box.has-advanced-upload .box__dragndrop {
    display: inline;
}

.box.is-dragover {
    background-color: grey;
}

.box.is-dragover * {
    pointer-events: none;
}

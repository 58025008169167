.budget-table {
    width: 100%;
    margin-bottom: 5px;
}

.budget-table td {
    /*background-color: white;*/
}

.budget-table table th {
    padding-right: 10px;
}

.budget-table table td {
    padding: 0px 10px;
}

.budget-table table tr:first-child td {
    background-color: olivedrab;
}

.budget-table table tr:last-child td {
    background-color: #f27256;
}

.budget-table .inner-budget-table {
    width: 20%;
}

.budget-table .inner-budget-table td {
    color: white;
}

.budget-table .expense-bar, .budget-table .budget-bar {
    height: 40px;
    line-height: 40px;
    width: 75%;
    display: inline-block;
    vertical-align: middle;
    color: black;
}

.budget-table .expense-bar {
    background-color: #f27256;
}

.budget-table .budget-bar {
    background-color: olivedrab;
}
input.special-input {
    background: rgba(78, 80, 84, 0.466);
    width: 100%;
    height: 100%;
    border: #6f6f6f solid 1px;
    color: white;
    padding-left: 15px
}

input.special-input::placeholder {
    color: white;
    opacity: 1;
}
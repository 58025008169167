.nav-outer-div {
    position: relative;
    display: block;
}

.nav-outer-div div, .nav-outer-div i {
    position: absolute;
    z-index: 999;
    right: 10px;
    border-radius: 4px;
}

.nav-outer-div div {
    background: white;
    transform: translateY(10px);
}

.nav-outer-div i {
    font-size: 26px;
    transform: translate(0px, -142px);
    background: transparent;
    padding: 5px;
}

.nav-outer-div i:hover {
    cursor: pointer;
}

.nav-inner-div > a.active {
    background: green;
    color: white;
}

div.nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(5, 5, 5, 0.355);
    transform: translate(0, 0);
}

.nav-tabs .nav-item.nav-link.lookup-buttons-panel {
    font-size: 16px;
    padding: 12px 12px 9px;
}

.nav-tabs .nav-item.nav-link.lookup-buttons-panel img {
    margin: 0px 6px 0 3px;
    width: 22px;
    height: 22px;
}

.nav-tabs.admin-menu .nav-item.nav-link.lookup-buttons-panel {
    font-size: 14px;
}

.nav-tabs.admin-menu .nav-item.nav-link.lookup-buttons-panel img {
    margin: 0px 3px 0 0px;
    width: 18px;
    height: 18px;
}

@media (max-width: 767px) {
    .nav-outer-div div.nav-inner-div {
        position: relative;
        width: 100%;
        height: 100%;
        background: rgba(63,63,63,.5);
        right: 0;
        top: -11px;
    }
    .nav-outer-div div.nav-inner-div div {
        right: 0;
    }
    .nav-outer-div div.nav-inner-div .lookup-buttons-panel {
        position: relative;
        display: block;
        background: rgba(63,63,63,.5);
        color: #fff;
        padding: 15px;
        border-bottom: 1px solid #fff;
        top: -11px;
    }
    .nav-outer-div div.nav-inner-div .lookup-buttons-panel img {
        margin: 0 12px 0 2px;
        width: 20px;
        height: 20px;
    }

    .nav-outer-div div.nav-inner-div .lookup-buttons-panel a {
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
    }

}